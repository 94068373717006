<template>
  <div v-loading="loading" class="pannel">
    <div class="pannel-con">
      <div class="top">
        <!-- <img class="item-image" src="@/assets/img/study/tati.png"> -->
        <div class="top-right">
          <div class="title-text">{{ title }}</div>
          <div class="flex">
            <!-- <el-progress
              :show-text="false"
              :stroke-width="12"
              :percentage="baifen"
              status="exception"
              color="#FF7B1C"
            />
            <div class="qusnum">
              <span class="have"
                >已完成{{
                  isSubmit
                    ? subjectList.length - detail.NosubjectName
                    : complete
                }}题</span
              >/共{{ subjectList.length }}题
            </div> -->
            <div class="exam-infor">
              试卷说明：本试卷共{{ subjectList.length }}道题，满分{{
                totalScore
              }}分，考试时间{{ examTime }}分钟
            </div>
          </div>
        </div>
      </div>
      <div :class="IsPurchase ? '' : 'content-box'">
        <div class="block">
          <div v-if="!wrongTopic" class="shiti-block">
            <div>
              <div class="subject-top">
                <!--  -->
                <div class="subject-type">
                  {{ queTypeNum[typeIndex] }}、{{
                    queType[subList[queCarBoxIndex]?.questionType]
                  }}<span>（共{{ typeTotal }}题，合计{{ subjectTotal }}分）</span>
                </div>
                <!-- <div class="subject-collect" @click="collecttestquestions()">
                  <i v-if="collectList.includes(testQuestionsId)" style="color: #ff952a" class="el-icon-star-on" />
                  <i v-else class="el-icon-star-off" />
                  收藏
                </div> -->
              </div>
              <div v-for="(item, index) of subList" v-show="queCarBoxIndex == index" ref="queCarBox" :key="index">
                <que-car v-if="ifNew" ref="queCar" :paper-id="paperId" :is-purchase="IsPurchase" :see-num="seeNum"
                  :start="start" :is-points="isPoints" :is-submited="isSubmit" :detail="item" :exam-type="examType"
                  :detail-index="index" :que-car-box-index="queCarBoxIndex" @getShitiList="getShitiList" @task="getNum"
                  @NextTopic="NextTopic" />
              </div>
            </div>
            <div class="dis_fle_between">
              <el-button class="queCarPrev" :disabled="queCarBoxIndex == 0" @click="previousTopic"><i
                  class="iconfont el-icon-arrow-left" /> 上一题</el-button>
              <el-button v-if="queCarBoxIndex != subList.length - 1" class="queCarNext" @click="NextTopic">下一题 <i
                  class="iconfont el-icon-arrow-right" /></el-button>
              <el-button v-else class="queCarNext" @click="ShowAnswerChange">交卷并查看答案
              </el-button>
            </div>
          </div>
          <div v-else style="width: 1220px" class="shiti-block">
            <div>
              <div v-for="(item, index) of subList" v-show="queCarBoxIndex == index" ref="queCarBox" :key="index">
                <que-car v-if="ifNew" ref="queCar" :paper-id="paperId" :is-purchase="IsPurchase" :see-num="seeNum"
                  :start="start" :is-points="isPoints" :is-submited="isSubmit" :detail="item" :detail-index="index"
                  :que-car-box-index="queCarBoxIndex" :wrong-topic="wrongTopic" :exam-type="examType"
                  @getShitiList="getShitiList" @task="getNum" />
                <!-- <div v-if="isSubmit" class="queBut" @click="queClick(item)">我要提问</div> -->
              </div>
            </div>

            <div class="dis_fle_between">
              <el-button class="queCarPrev" :disabled="queCarBoxIndex == 0" @click="previousTopic"><i
                  class="iconfont el-icon-arrow-left" /> 上一题</el-button>
              <el-button v-if="queCarBoxIndex != subList.length - 1" class="queCarNext" @click="NextTopic">下一题 <i
                  class="iconfont el-icon-arrow-right" /></el-button>
              <el-button v-else class="queCarNext" @click="ShowAnswerChange">交卷并查看答案
              </el-button>
            </div>
          </div>
          <!-- 左侧 -->
          <div v-if="!wrongTopic" class="shiti-function">
            <div class="timebox">
              <div class="time-title">
                <i class="iconfont icon-daojishi" /> 剩余时间
              </div>
              <div class="time-body">
                <span v-if="time" class="countdown">
                  {{ time.hours > 9 ? time.hours : "0" + time.hours }}:{{
                    time.minutes > 9 ? time.minutes : "0" + time.minutes
                  }}:{{ time.seconds > 9 ? time.seconds : "0" + time.seconds }}
                </span>
              </div>
            </div>
            <!-- 答题卡 -->
            <div class="sheet numberPannel m-t-20">
              <div class="sheet-header">
                <div v-if="!isSubmit" class="title">
                  <i class="el-icon-tickets" />
                  答题卡
                </div>
              </div>
              <!-- 试题类型 -->
              <div v-if="!wrongTopic" class="sheet-block">
                <answer-sheet v-for="(list, index) in allLists" :key="index" ref="'answerSheetRef" :sindex="index"
                  :subject-type-list="list" :collect-list="collectList" :is-submit="isSubmit" @jumpShiTi="jumpShiTi" />
              </div>
              <div style="display: flex; justify-content: center">
                <div class="flex-bottom">
                  已作
                  <div style="
                      width: 10px;
                      height: 10px;
                      border-radius: 50%;
                      background: #dddddd;
                    " />
                </div>
                <div class="flex-bottom">
                  未作
                  <div style="
                      width: 10px;
                      height: 10px;
                      border-radius: 50%;
                      border: 1px solid #dddddd;
                    " />
                </div>
                <!-- <div class="flex-bottom">
                  收藏<i style="color: #ff952a" class="el-icon-star-on" />
                </div> -->
              </div>
              <!-- 交卷 -->
              <div v-if="!isSubmit" style="
                  color: #1c81f5;
                  border: 1px solid #1c81f5;
                  background: #ffffff;
                " class="btnlist" @click="saveProgress">
                保存进度 下次继续
              </div>
              <div v-if="!isSubmit" class="btnlist" @click="ShowAnswerChange">
                直接交卷
              </div>
              <div v-if="isSubmit && examType != 3" class="btnlist" @click="answerAgen">
                重新答题
              </div>
            </div>
          </div>
        </div>
        <div v-if="!IsPurchase" class="quest-tips">试看模式</div>
        <div v-if="!IsPurchase" class="quest-seek">
          <div class="seek">
            <div class="text">
              <i class="iconfont icon-suotou" />试看结束，购买解锁全部试题
            </div>
            <div v-if="!isSubmit" class="btnlist" @click="goAllOrder">
              立即购买
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="提示" :show-close="false" :close-on-click-modal="false" :close-on-press-escape="false"
      :visible.sync="centerDialogVisible" width="30%" center>
      <span>试卷尚未购买无法答题</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="goBack">返回上一页</el-button>
        <el-button type="primary" @click="goOrder">去购买</el-button>
      </span>
    </el-dialog>
    <!-- 答疑 -->
    <!-- 课程答疑 -->
    <answer-view v-if="answerShow" :type="2" :status="4" :course-id="paperId" :is-purchase="IsPurchase"
      :chapter-id="queTi.subjectId" :if-list="false" @close="close" />
  </div>
</template>

<script>
import Bus from "@/api/bus";
import Vue from "vue";
import { getInfo, getToken } from "@/api/cookies";
import { AnswerClient } from "@/api/task/answerClient";
const answerClient = new AnswerClient();
import QueCar from "./queCar.vue";
import AnswerSheet from "./answerSheet.vue";
import { queType, queTypeNum } from "@/api/emun";
import { OrderClient } from "@/api/orderClient";
const orderClient = new OrderClient();
import { Know } from "@/api/know";
const know = new Know();
import answerView from "@/components/Know/course/answerView.vue";
import { checkStudyCode } from "@/api/home.js";
import { MenuItemGroup } from "element-ui";
import { getSubjectIdList } from "@/api/course/course";

export default {
  components: {
    QueCar,
    answerView,
    AnswerSheet,
    /* ExamFeedBack, ExamAnalysis */
  },
  data() {
    return {
      ifNew: true,
      queCarBoxIndex: 0,
      IsPurchase: 0, // 是否购买
      centerDialogVisible: false,
      testQuestionsId: null, // 试题id
      detail: {},
      queType: queType,
      queTypeNum: queTypeNum,
      title: "",
      totalScore: 0, // 总分
      examTime: 0, // 考试时间
      paperId: "", // 试卷ID
      roomId: "", // 考场id
      type: null /* 1 知识套餐，2 直播    */,
      courseId: null,
      showId: null,
      classId: null,
      examType: 3, // 1 每日一练, 2 章节练习 随堂练习, 3 虚拟考场,4 试卷包 试卷  5  课程模考
      userInfo: {},
      isSubmit: false, // 是否显示解析
      isSubmitList: false, // 是否获取解析列表
      isPoints: true, // 是否显示得分
      isShow: false, // 是否显示成绩
      subjectList: [], // 题目列表//提交时用勇于显示题号
      subList: [], // 题目列表-提交时用
      allLists: [], // 根据题型重组的数组
      collectList: [], // 收藏试题列表
      /* 计时器 */
      start: true, // 是否计时
      timeEnd: "",
      time: {
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      /* 做题数 */
      complete: 0,
      baifen: 0,
      maxIndex: 0,
      /* 加载中 */
      loading: false,
      showQue: false,
      num: 0,
      canClick: true,
      /* 题型 */
      TiXing: [0, 0, 0, 0, 0, 0],
      points: 0, // 试卷分数s
      next: true, // 试卷分数
      /* 试看 */
      seeNum: null,
      /* 产品详情 */
      orderDetail: {},
      answerShow: false,
      wrongTopic: false,
      queTi: null,
      cardCode: null,

      timer: null, // 定时器
      /* 分类型合计题目 */
      typeIndex: 1,
      subjectType: null,
      typeTotal: null,
      subjectTotal: null,
    };
  },
  watch: {
    testQuestionsId(n, o) {
      this.typeTotal = this.allLists[this.typeIndex].length;
      let fraction = 0;
      this.allLists[this.typeIndex].map((item) => {
        fraction += item.score;
      });
      this.subjectTotal = fraction;
    },
  },
  async created() {
    this.cardCode = this.$route.query.cardCode;
    this.checkStudyCode();
    this.courseId = this.$route.query.courseId
      ? this.$route.query.courseId
      : null;
    this.showId = this.$route.query.showId
      ? Number(this.$route.query.showId)
      : null;
    this.classId = this.$route.query.classId
      ? Number(this.$route.query.classId)
      : null;
    this.wrongTopic = this.$route.query.wrongTopic
      ? this.$route.query.wrongTopic
      : null;
    this.isSubmit = String(this.$route.query.isSubmited) == "true";
    this.paperId = this.$route.query.paperId
      ? String(this.$route.query.paperId)
      : null;
    this.IsPurchase = this.$route.query.IsPurchase == "true";
    this.roomId = this.$route.query.roomId
      ? String(this.$route.query.roomId)
      : null;
    this.examType = this.$route.query.examType
      ? Number(this.$route.query.examType)
      : null;
    this.type = this.$route.query.type ? Number(this.$route.query.type) : null;
    this.title = this.$route.query.title
      ? String(this.$route.query.title)
      : null;
    this.userInfo = getInfo();
    this.$nextTick(() => {
      if (!this.userInfo) {
        Vue.prototype.goLoginView(false);
        return;
      }
    });
    this.isSubmitList = this.isSubmit;
    if (this.examType !== 168) {
      await this.getShitiList();
    } else {
      const res = await getSubjectIdList(JSON.parse(this.$route.query.param));
      if (res.code === 0) {
        this.Splicing(res.data, true);
      }
    }

    // 滚动条监控  答题卡 滚动到顶部悬浮
    if (this.IsPurchase) {
      $(document).scroll(function () {
        // 滚动到上面  隐藏
        $(window).scroll(function () {
          const top = $(window).scrollTop();
          if (top >= 24) {
            $(".shiti-function").addClass("fixed-card");
          } else {
            $(".shiti-function").removeClass("fixed-card");
          }
        });
      });
    }
    if (this.type != 12) {
      if (this.classId) {
        await know.getkonDetail(this.classId, 1, this.classId).then((res) => {
          this.orderDetail = res;
          if (!this.cardCode && !this.IsPurchase) {
            this.IsPurchase = res.IsPurchase;
          }
        });
      } else {
        await know
          .getkonDetail(
            this.type == 1 ? this.classId : this.courseId,
            this.type,
            this.classId
          )
          .then((res) => {
            this.orderDetail = res;
            if (!this.cardCode && !this.IsPurchase) {
              this.IsPurchase = res.IsPurchase;
            }
          });
      }
    }
    this.$forceUpdate();
    if (!this.isSubmit && this.IsPurchase) {
      this.timeInterval();
    }
    this.ifNew = false;
    this.$nextTick(() => {
      this.ifNew = true;
    });
  },
  beforeRouteLeave(to, form, next) {
    // 离开路由移除滚动事件
    if (this.isSubmited) {
      window.removeEventListener("scroll", this.scrollToTop);
    }
    next();
  },

  methods: {
    /* 验证学习卡 */
    checkStudyCode() {
      if (this.cardCode) {
        const data = {
          cardCode: this.cardCode,
        };
        checkStudyCode(data).then((res) => {
          if (res.code == 0) {
            this.IsPurchase = true;
            this.$store.commit("IS_PURCHASE_CHANGE", true);
          }
        });
      }
    },
    getFraction(list) {
      let fraction = 0;
      list.map((item) => {
        fraction += item.score;
      });
      return fraction;
    },
    /* 判断是否购买 */
    getDetail() { },
    // 计时器
    timeInterval() {
      this.timeEnd = setInterval(() => {
        // 回掉函数开始计时了
        this.time.seconds--;
        if (this.time.seconds == 0) {
          this.time.seconds = 60;
          this.time.minutes--;
        }
        if (this.time.minutes == 0) {
          this.time.minutes = 60;
          this.time.hours--;
        }
      }, 1000);
    },
    // 获取试卷列表
    async getShitiList(val) {
      if (this.subList.length - 1 == 0) {
        this.$router.push("/seventhPage/myexamination");
      }
      // this.queCarBoxIndex = this.queCarBoxIndex - val
      this.loading = true;
      if (!this.isSubmitList) {
        if (this.wrongTopic) {
          const res = await answerClient.getMistakesSubjectList(this.paperId);
          if (res.code == 0) {
            // const topic = res.data.filter((element) => {
            //   return element.questionType != 4
            // })
            this.Splicing(res.data, true);
          }
        } else {
          await answerClient
            .selectPartnershipPaperSubjectList(
              this.paperId,
              this.userInfo ? this.userInfo.id : undefined,
              this.classId ? this.classId : undefined,
              this.paperRoomId ? this.roomId : undefined,
              this.examType ? this.examType : undefined
            )
            .then((res) => {
              if (res.code == 0) {
                if (this.type == 12) {
                  this.IsPurchase = res.data.status == 1;
                }
                this.title = res.data.paper.name;
                this.totalScore = res.data.paper.totalScore;
                this.examTime = res.data.paper.examTime;
                this.seeNum = res.data.paper.seeNum;
                this.Splicing(res.data.data, true);
              }
            });
        }
      } else {
        if (this.examType == 3) {
          await answerClient
            .goOverPcSubjectAnswer(this.paperId, this.roomId, this.examType)
            .then((res) => {
              if (res.code == 0) {
                this.detail = res.data;
                this.totalScore = res.data.paper.totalScore;
                this.points = res.data.examResult.points;
                this.Splicing(res.data.arrayList, false);
              }
            });
        } else if (this.wrongTopic) {
          await answerClient
            .getPcSubjectAnswer(this.paperId, this.roomId, this.examType)
            .then((res) => {
              if (res.code == 0) {
                this.detail = res.data;
                this.isPoints = false;
                this.totalScore = res.data.paper.totalScore;
                this.points = res.data.examResult.points;
                this.Splicing(this.subList, false);
              }
            });
        } else {
          await answerClient
            .getPcSubjectAnswer(this.paperId, this.roomId, this.examType)
            .then((res) => {
              if (res.code == 0) {
                this.detail = res.data;
                this.totalScore = res.data.paper.totalScore;
                this.points = res.data.examResult.points;
                this.Splicing(res.data.arrayList, false);
              }
            });
        }
        this.getNum();
      }
      this.$forceUpdate();
      this.loading = false;
    },
    /* 试题拼接 */
    Splicing(list, ans) {
      this.TiXing = [0, 0, 0, 0, 0, 0];
      /* ans  是否是提交显示的 */
      let shiti = [];
      for (const item of list) {
        item.questionType = Number(item.questionType);
        if (
          item.questionType == 0 ||
          item.questionType == 1 ||
          item.questionType == 6
        ) {
          const data = JSON.parse(item.questionOption);
          const sub = [];
          for (const i in data) {
            const val = {
              val: data[i],
              num: String.fromCharCode(Number(i) + 65),
            };
            sub.push(val);
          }
          item.optionList = sub;
          if (ans && (item.questionType == 1 || item.questionType == 6)) {
            item.questionUserAnswer = [];
          }
          if (!ans && (item.questionType == 1 || item.questionType == 6)) {
            if (
              item.questionUserAnswer &&
              item.questionUserAnswer.length > 0 &&
              item.questionUserAnswer instanceof Array
            ) {
              // item.questionUserAnswer = item.questionUserAnswer.split(",");
            } else {
              item.questionUserAnswer = [];
            }
          }
          shiti.push(item);
        } else if (item.questionType == 2) {
          item.optionList = [
            { val: "对", num: "A" },
            { val: "错", num: "B" },
          ];
          shiti.push(item);
        } else if (item.questionType == 5) {
          if (item.childSubject) {
            for (const chil of item.childSubject) {
              chil.questionType = Number(chil.questionType);
              if (
                chil.questionType == 0 ||
                chil.questionType == 1 ||
                chil.questionType == 6
              ) {
                const data = JSON.parse(chil.questionOption);
                const sub = [];
                for (const i in data) {
                  const val = {
                    val: data[i],
                    num: String.fromCharCode(Number(i) + 65),
                  };
                  sub.push(val);
                }
                chil.optionList = sub;
                if (ans && (chil.questionType == 1 || chil.questionType == 6)) {
                  chil.questionUserAnswer = [];
                }
                if (
                  !ans &&
                  (chil.questionType == 1 || chil.questionType == 6)
                ) {
                  if (chil.questionUserAnswer) {
                    chil.questionUserAnswer =
                      chil.questionUserAnswer.split(",");
                  } else {
                    chil.questionUserAnswer = [];
                  }
                }
              } else if (chil.questionType == 2) {
                chil.optionList = [
                  { val: "对", num: "A" },
                  { val: "错", num: "B" },
                ];
              }
            }
            shiti = shiti.concat(item.childSubject);
          }
        } else {
          shiti.push(item);
        }
      }
      this.subjectList = shiti;
      this.testQuestionsId = shiti[0].id;
      for (let i = 0; i < 7; i++) {
        this.allLists.push(
          shiti.filter((item, index) => {
            if (item.questionType === i) {
              item.subjectIndex = index;
              return item;
            }
          })
        );
      }
      this.time = {
        hours: parseInt(this.examTime / 60),
        minutes: this.examTime - 60 * parseInt(this.examTime / 60) - 1,
        seconds: 60,
      };
      this.allLists = this.allLists.filter((s, index) => {
        if (index === 0) {
          //
          this.typeIndex = 0;
          this.typeTotal = s.length;
          let fraction = 0;
          s.map((item) => {
            fraction += item.score;
          });
          this.subjectTotal = fraction;
        }
        return s.length != 0;
      });
      this.subList = list; // 提交时用  同数据源数据会一起变化
      // 添加 字段
      for (const i in this.subjectList) {
        const item = this.subjectList[i];
        item.indexVal = Number(i) + 1;
        this.TiXing[item.questionType]++;
      }
    },
    /* 答案填写 */
    isChoose(item, val) {
      if (item.questionType === 0 || item.questionType === 2) {
        if (this.subjectIndex < this.subjectList.length - 1) {
          this.subjectIndex += 1;
        }
      }
    },

    // 开始or暂停
    StartOrEnd() {
      if (this.isSubmit) {
        return;
      }
      if (this.start) {
        clearTimeout(this.timeEnd); // 清除
        this.start = false;
      } else {
        this.timeInterval();
        this.start = true;
      }
      this.$forceUpdate();
    },

    /* 计算做题数 */
    async getNum() {
      this.complete = 0;
      this.next = false;

      for (const item of this.subjectList) {
        if (item.questionType == 1 || item.questionType == 6) {
          if (item.questionUserAnswer.length > 0) {
            await this.complete++;
          }
        } else {
          if (item.questionUserAnswer) {
            await this.complete++;
          }
        }
      }
      const data = (this.complete / Number(this.subjectList.length)) * 100;
      this.baifen = Math.round(data);
      this.$forceUpdate();
    },
    // 提交
    async ShowAnswerChange() {
      console.log(this.allLists, 'allLists');
      console.log(this.subjectList.length - this.complete, 'this.subjectList.length - this.complete');
      if (!this.canClick) {
        return;
      }
      this.canClick = false;
      const that = this;
      this.StartOrEnd();
      if (this.userInfo != null) {
        if (this.subjectList.length - this.complete > 0) {
          this.$confirm(`<p style='text-align: center;'> 您还有<span style='color: #1C81F5;'> ${this.subjectList.length - this.complete} </span>道题未做答 </p> <p style='text-align: center;'>是否确认交卷？</p>`, "温馨提示", {
            confirmButtonText: "确定交卷",
            cancelButtonText: "取消",
            dangerouslyUseHTMLString: true,
            center: true,
            showClose: false,
            customClass: 'confirmClass'
          })
            .then(() => {
              that.postEvery();
            })
            .catch(() => {
              this.StartOrEnd();
            });
        } else {
          this.$confirm("确认交卷吗？", "提交", {
            confirmButtonText: "提交",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              that.postEvery();
            })
            .catch(() => {
              this.StartOrEnd();
            });
        }
      }
      this.canClick = true;
      this.ifNew = false;
      this.$nextTick(() => {
        this.ifNew = true;
      });
      this.$forceUpdate();
    },
    /* 重新答题 */
    answerAgen() {
      window.location.reload();
    },
    previousTopic() {
      this.next = false;
      if (this.queCarBoxIndex == 0) {
        return;
      } else {
        this.queCarBoxIndex--;
        this.testQuestionsId = this.subjectList[this.queCarBoxIndex].id;
        if (
          this.subjectList[this.queCarBoxIndex].questionType !==
          this.subjectList[this.queCarBoxIndex + 1].questionType
        )
          this.typeIndex--;
        this.jumpShiTi(
          this.testQuestionsId,
          this.typeIndex,
          this.queCarBoxIndex
        );
      }
    },
    NextTopic(val) {
      if (this.queCarBoxIndex >= this.subjectList.length - 1) {
        return;
      } else {
        this.queCarBoxIndex++;
        if (this.maxIndex <= this.queCarBoxIndex) {
          this.maxIndex = this.queCarBoxIndex;
          this.next = true;
        }
        if (this.queCarBoxIndex == this.subjectList.length - 1) {
          this.next = true;
        }
        this.testQuestionsId = this.subjectList[this.queCarBoxIndex].id;
        if (
          this.subjectList[this.queCarBoxIndex].questionType !==
          this.subjectList[this.queCarBoxIndex - 1].questionType
        )
          this.typeIndex++;
        this.jumpShiTi(
          this.testQuestionsId,
          this.typeIndex,
          this.queCarBoxIndex
        );
      }
    },
    // 保存进度
    async saveProgress() {
      this.loading = true;
      const times =
        (await (this.time.hours > 0 ? this.time.hours * 60 * 60 : 0)) +
        (this.time.minutes > 0 ? this.time.minutes * 60 : 0) +
        this.time.seconds;
      const data = await {
        roomId: this.roomId,
        examType: this.examType,
        paperId: this.paperId,
        questionSubjectPaperVoList: this.subList,
        answerTimes: Math.round(times / 1000) || 0,
        courseId: this.type == 6 ? undefined : this.courseId,
        showId: this.showId,
      };
      if (!this.wrongTopic) {
        await answerClient
          .savePcSubjectAnswerSchedule(data)
          .then(async (res) => {
            if (res.code == 0) {
              this.StartOrEnd();
              clearTimeout(this.timeEnd); // 清除
              this.allLists = [];
              await this.getShitiList();
            }
          });
      } else {
        this.StartOrEnd();
        clearTimeout(this.timeEnd); // 清除
        this.allLists = [];
        await this.getShitiList();
      }
      this.$nextTick(() => {
        // 监听滚动事件
        window.addEventListener("scroll", this.scrollToTop);
        this.$forceUpdate();
      });
    },
    // 提交答案
    async postEvery() {
      this.loading = true;
      const times =
        (await (this.time.hours > 0 ? this.time.hours * 60 * 60 : 0)) +
        (this.time.minutes > 0 ? this.time.minutes * 60 : 0) +
        this.time.seconds;
      const data = await {
        roomId: this.roomId,
        examType: this.examType,
        paperId: this.paperId,
        questionSubjectPaperVoList: this.subList,
        answerTimes: Math.round(times / 1000) || 0,
        courseId: this.type == 6 ? undefined : this.courseId,
        showId: this.showId,
      };
      /* 其他  */
      if (!this.wrongTopic) {
        await answerClient.addPcSubjectAnswer(data).then(async (res) => {
          if (res.code == 0) {
            this.$router.push({
              path: "/partnerPage/report",
              query: {
                title: this.title,
                paperId: this.paperId,
                examType: this.examType,
                roomId: this.roomId,
              },
            });
            this.StartOrEnd();
            clearTimeout(this.timeEnd); // 清除
            this.isSubmitList = true;
            await this.getShitiList();
          }
        });
      } else {
        this.StartOrEnd();
        clearTimeout(this.timeEnd); // 清除
        this.isSubmitList = true;
        await this.getShitiList();
        this.isSubmit = true;
      }
      this.$nextTick(() => {
        this.isSubmit = true;
        // 监听滚动事件
        window.addEventListener("scroll", this.scrollToTop);
        this.$forceUpdate();
      });
    },
    close() {
      this.answerShow = false;
      this.$forceUpdate();
    },
    // 跳转试题
    jumpShiTi(id, index, subjectIndex) {
      this.testQuestionsId = id;
      this.queCarBoxIndex = subjectIndex;
      // this.typeIndex = item.typeIndex
      // this.typeTotal = item.typeTotal
      // this.subjectTotal = item.subjectTotal
      this.typeIndex = index;
    },
    // 立即购买
    goOrder() {
      const token = getToken();
      if (token) {
        orderClient
          .addOrders(this.paperId, this.userInfo.id, this.title, 12)
          .then((res) => {
            if (res.code == 0) {
              this.$router.push({
                name: "个人中心订单页",
                params: { orderNumber: res.msg },
              });
            } else {
              this.$message.error(res.msg);
            }
          });
      } else {
        Vue.prototype.goLoginView(true);
        // this.$router.push({ name: "登录页", params: { hzc: 1 } });
      }
    },
    goAllOrder() {
      const token = getToken();
      if (token) {
        if (this.classId) {
          orderClient
            .addOrders(
              this.classId,
              this.userInfo.id,
              this.orderDetail.title,
              1
            )
            .then((res) => {
              if (res.code == 0) {
                this.$router.push({
                  name: "个人中心订单页",
                  params: { orderNumber: res.msg },
                });
              } else {
                this.$message.error(res.msg);
              }
            });
        } else {
          let id = null;
          if (this.type == 1) {
            id = this.classId;
          } else if (this.type == 12) {
            id = this.paperId;
          } else {
            id = this.courseId;
          }
          orderClient
            .addOrders(
              id,
              this.userInfo.id,
              this.type == 12 ? this.title : this.orderDetail.title,
              this.type
            )
            .then((res) => {
              if (res.code == 0) {
                this.$router.push({
                  name: "个人中心订单页",
                  params: { orderNumber: res.msg },
                });
              } else {
                this.$message.error(res.msg);
              }
            });
        }
      } else {
        Vue.prototype.goLoginView(true);
      }
    },
    queClick(item) {
      this.answerShow = true;
      this.queTi = item;
    },
    // 收藏试题
    async collecttestquestions() {
      const isIncloud = this.collectList.find((item) => {
        if (item === this.testQuestionsId) {
          return item === this.testQuestionsId;
        }
      });
      if (isIncloud) {
        this.collectList = this.collectList.filter(
          (item) => item !== this.testQuestionsId
        );
      } else {
        this.collectList.push(this.testQuestionsId);
      }
    },
    scrollToTop() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.isElementNotInViewport(this.$refs.queCarBox);
      }, 500);
    },
    isElementNotInViewport(el) {
      if (el) {
        el.forEach((item, index) => {
          const rect = el[index].getBoundingClientRect();
          if (
            !(
              rect.top >=
              (window.innerHeight || document.documentElement.clientHeight) ||
              rect.bottom <= 0
            )
          ) {
            if (!this.subList[index].isShow) {
              this.$refs.queCar[index].init();
              this.subList[index].isShow = true;
            }
          }
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.queCarNext,
.queCarPrev {
  flex: 1;
  margin-left: 0;
  width: 50%;
  //  width: 50%;
}

.flex-bottom {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #999999;
  margin: 5px;
}

.pannel-con {
  width: 1220px;
  margin: 0px auto;

  .shijuan-block {
    position: relative;
    width: 1200px;
    margin: auto;
    padding: 20px 30px;
    margin-bottom: 20px;
    background-color: #fff;

    margin-top: 30px;

    .shijuan-type {
      display: flex;
      align-items: center;
      color: #333333;
      font-size: 14px;

      i {
        font-size: 20px;
        margin-right: 5px;
      }
    }

    .shijuan-title {
      text-align: center;
      font-size: 22px;
      margin: 0;
      padding: 2px 0px 27px;
      border-bottom: 1px dashed #e0e3e9;

      .grade {
        position: absolute;
        left: 40px;
        top: 0px;
        width: 150px;
        height: 80px;
        font-size: 50px;
        font-family: qiantumakeshouxieti;
        font-weight: 400;
        line-height: 80px;
        color: #ff5104;
      }
    }

    .content {
      font-size: 16px;
      color: #666;
      line-height: 2;
      padding-left: 60px;
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

  .warning {
    background-color: #fff9e9;
    border: 1px dashed #ffeec4;
    text-align: center;
    line-height: 2.3;
    font-size: 16px;
    color: #ed7321;
  }

  .block {
    width: 1220px;
    margin: auto;
    overflow: hidden;

    .shiti-block {
      float: left;
      width: 974px;
      position: relative;
      background: #ffffff;
      border-radius: 8px;

      .subject-top {
        display: flex;
        justify-content: space-between;
        background: #f9fbff;
        height: 64px;
        line-height: 64px;

        .subject-type {
          font-size: 16px;
          padding-left: 20px;
          color: #333333;

          span {
            color: #999999;
            font-size: 12px;
          }
        }

        .subject-collect {
          font-size: 14px;
          color: #666666;
          padding-right: 20px;
          display: flex;
          align-items: center;

          i {
            font-size: 20px;
          }
        }
      }
    }

    // <!-- 左侧 -->
    .shiti-function {
      width: 210px;
      float: right;


      // min-height: 300px;
      /* 答题时间 */
      .timebox {
        width: 100%;
        background: #ffffff;
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
        font-size: 18px;
        height: 130px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        text-align: center;

        .time-title {
          padding: 20px 0 12px 0px;

          font-size: 14px;
          font-family: Source Han Sans CN, Source Han Sans CN;
          font-weight: 400;
          color: #666666;
        }

        .time-body {
          background: #f6faff;
          border-radius: 6px 6px 6px 6px;
          // padding: 13px 33px;
          width: 170px;
          height: 50px;
          line-height: 50px;
          margin: 0 auto;
        }

        .iconfont {
          font-size: 18px;
          // margin-right: 9px;
        }

        .countdown {
          color: #1c81f5;

          font-size: 26px;
          font-family: Source Han Sans CN, Source Han Sans CN;
          font-weight: 500;
          color: #1c81f5;
        }

        .red {
          color: red !important;
        }
      }

      .sheet {
        width: 100%;
        background-color: #fff;
        padding: 0px 20px 20px;

        .sheet-header {
          width: 100%;
          line-height: 58px;
          height: 58px;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;

          .title {
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #666666;
            line-height: 16px;
            text-align: center;
            flex-shrink: 0;

            .icon {
              position: absolute;
              left: 15px;
              top: 40px;
              width: 18px;
              height: 6px;
            }
          }

          .num {
            font-size: 14px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #666666;
            line-height: 14px;

            .iconfont {
              font-size: 8px;
              margin-left: 12px;
              margin-right: 4px;
            }
          }
        }

        .sheet-block {
          max-height: 200px;
          overflow-y: scroll;
          flex-wrap: wrap;
          border-bottom: 1px solid #eeeeee;

          .t-item {
            width: 22px;
            height: 22px;
            border-radius: 3px 3px 3px 3px;
            opacity: 1;
            font-size: 12px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            color: #999999;
            line-height: 22px;
            text-align: center;
            cursor: pointer;
            margin: 8px 12px 10px 0px;

            .t-item {
              border: 1px solid #eeeeee;
              line-height: 22px;
              margin: 0px;
            }

            .t-item-hasanswer {
              border: 1px solid #eeeeee;
              background-color: #eeeeee;
              line-height: 22px;
              height: 22px;
              border-radius: 3px 3px 3px 3px;
              margin: 0px;
            }

            &:hover {
              background-color: #ee491f;
              color: #f5deb3;
            }
          }

          .t-on {
            border: 1px solid #38c25f;
            background: #38c25f;
            color: #ffffff;
          }

          .t-error {
            border: 1px solid #2586f5;
            background: #2586f5;
            color: #ffffff;
          }

          .t-no {
            border: 1px solid #666;
            background: #666;
            color: #ffffff;
          }

          .t-sucess {
            border: 1px solid #38c25f;
            background: #38c25f;
            color: #ffffff;
          }

          .sheet-subject {
            font-size: 14px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            margin: 10px 0px;
            color: #666666;

            span {
              font-size: 12px;
              font-family: Source Han Sans CN-Regular, Source Han Sans CN;
              font-weight: 400;
              color: #999999;
            }
          }
        }

        .btnlist {
          width: 170px;
          height: 50px;
          line-height: 50px;
          margin-top: 10px;
        }
      }
    }

    /*  */
    .fixed-card {
      position: fixed;
      top: 116px;
      right: calc(50% - 610px);
    }
  }
}

.btnlist {
  background: #1c81f5;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  font-size: 16px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
  cursor: pointer;
}

/* 暂停 */
.start-mask {
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 999;
  color: #fff;
  font-size: 30px;
  background: rgba(0, 0, 0, 0.23);

  .text {
    text-align: center;
    color: #fff;
    width: 910px;
    position: fixed;
    bottom: 50vh;
    /* margin-top: 50px; */
    z-index: 10;
  }
}

/*  */
.back {
  width: 112px;
  height: 34px;
  background: #ffffff;
  border: 1px solid #a8abbe;
  border-radius: 17px;
  font-size: 16px;
  font-family: MicrosoftYaHei;
  line-height: 32px;
  color: #333333;

  text-align: center;
  z-index: 10;
  margin: 16px 0px 16px 18px;
}

/* 标题 */
.top {
  width: 100%;
  padding: 20px;
  background: #fff;
  height: 120px;
  display: flex;
  margin-bottom: 20px;

  .item-image {
    width: 60px;
    height: 100%;
    margin-right: 12px;
    flex-shrink: 0;
  }

  .top-right {
    width: 100%;
    height: 100%;
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title-text {
      font-size: 20px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #333333;
      line-height: 0px;
    }

    .exam-infor {
      font-size: 12px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #999999;
      line-height: 0px;
    }
  }
}

.content-box {
  position: relative;
  width: 100%;

  .block {
    padding-bottom: 100px !important;
  }

  .quest-tips {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 96px;
    height: 32px;
    background: #ff5e51;
    border-radius: 0px 8px 0px 8px;
    opacity: 1;
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 32px;
    text-align: center;
  }

  .quest-seek {
    position: absolute;
    z-index: 100;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background: rgba(153, 153, 153, 0.2);
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    border: 1px solid #cccccc;

    .seek {
      position: absolute;
      bottom: 0px;
      width: 100%;
      height: 88px;
      background: #ffffff;
      border-radius: 0px 0px 8px 8px;
      opacity: 1;
      margin: 0px auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 32px;
      margin-top: 12px;

      .text {
        font-size: 18px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #666666;
        line-height: 18px;

        .iconfont {
          font-size: 18px;
          color: #666666;
          margin-right: 12px;
        }
      }

      .btnlist {
        margin: 0px;
        width: 170px;
        line-height: 48px;
        height: 48px;
      }
    }
  }
}

//隐藏滚动条
::-webkit-scrollbar {
  display: none !important;
  scrollbar-width: none !important;
  -ms-overflow-style: none;
}

.queBut {
  width: 104px;
  height: 38px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  border: 1px solid #ff5e51;
  margin: 10px 0px;
  font-size: 14px;
  font-family: Microsoft YaHei-Regular, Microsoft YaHei;
  font-weight: 400;
  color: #ff5e51;
  line-height: 38px;
  text-align: center;
  cursor: pointer;
}
</style>
<style  lang="less">
.confirmClass {
  .el-message-box__header {
    padding-top: 15px;

  }

  .el-message-box__btns {
    justify-content: space-around;

    .el-button {
      width: 90px;
      height: 40px;
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
      padding: 0;
    }
  }

  .el-message-box__header {
    background: #F9FCFF;
  }
}
</style>
import { render, staticRenderFns } from "./examDetailpartner.vue?vue&type=template&id=d5da7424&scoped=true&"
import script from "./examDetailpartner.vue?vue&type=script&lang=js&"
export * from "./examDetailpartner.vue?vue&type=script&lang=js&"
import style0 from "./examDetailpartner.vue?vue&type=style&index=0&id=d5da7424&prod&lang=less&scoped=true&"
import style1 from "./examDetailpartner.vue?vue&type=style&index=1&id=d5da7424&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d5da7424",
  null
  
)

export default component.exports